import React, { useContext, useState, useEffect } from 'react'
import { supabase } from '../helpers/sbClient'
import {useDispatch, useSelector} from "react-redux";
import {getUserById} from "../store/features/user/thunks";
import {setLoading} from "../store/features/app";

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const companyId = useSelector(state=>state.user?.companyid)
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [userRole, setUserRole] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        const session = supabase.auth.session()

        setUser(session?.user ?? null)
        setIsLoading(false)

        const { data: listener } = supabase.auth.onAuthStateChange(
            async (event, session) => {
                setUser(session?.user ?? null)
                if(session?.user){
                    dispatch(getUserById( session.user.id)).then(res=>{
                        setUserRole(res.payload.data.role.accesslevel)
                    })
                }
                setIsLoading(false)
            }
        )

        return () => {
            listener?.unsubscribe()
        }
    }, [dispatch])
useEffect(()=>{
    if(user && !companyId){

        dispatch(setLoading(true))
        dispatch(getUserById(user.id)).then(res=>{
            setUserRole(res.payload.data.role.accesslevel)
            dispatch(setLoading(false))
        })
    }

},[user, companyId])
    const value = {
        signIn: (data) => supabase.auth.signIn(data),
        signOut: () => supabase.auth.signOut(),
        user,
        userRole
    }

    return (
        <AuthContext.Provider value={value}>
            {!isLoading && children}
        </AuthContext.Provider>
    )
}
