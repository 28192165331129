import {createAsyncThunk} from '@reduxjs/toolkit'
import {fetchAPI} from "../../../helpers/fetchAPI";

export const getEntryById = createAsyncThunk(
    'entry/getEntryById',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/entry/byId`, payload)
    }
)

export const getEntries = createAsyncThunk(
    'entry/getEntries',
    async (_, {getState}) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/entry/list`)
    }
)

export const getCurrentMonthEntriesByUser = createAsyncThunk(
    'entry/getCurrentMonthEntriesByUser',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/entry/currentMonthEntriesByUser`, payload)
    }
)

export const updateEntry = createAsyncThunk(
    'entry/updateEntry',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/entry/update`, payload)
    }
)

export const addEntry = createAsyncThunk(
    'entry/addEntry',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/entry/add`, payload)
    }
)

export const sendEmail = createAsyncThunk(
    'entry/sendEmail',
    async (payload) => {

        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/entry/sendEmail`, payload)
    }
)
export const sendSMS = createAsyncThunk(
    'entry/sendSms',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/entry/sendSms`, payload)
    }
)

// Checvk on facebook error
export const postToFBPage = createAsyncThunk(
    'entry/postToFacebook',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/entry/postToFacebook`, payload)
    }
)
