import React, {useEffect} from "react";
import {getCurrentAndLastMonthTopEntries} from "../../store/features/dashboard/thunks";
import {useDispatch, useSelector} from "react-redux";
import Chart from "react-apexcharts";
import {IonCard, IonCardContent} from "@ionic/react";
import Spinner from "../Spinner.js"


const CurrentPreviousEntriesChart = ({options}) => {
    const dispatch = useDispatch();
    const {companyId} = options;

    useEffect(() => {
        if(companyId){
            dispatch(getCurrentAndLastMonthTopEntries({companyId}));
        }
    }, [dispatch, companyId])
    const topEntries = useSelector(state => state.dashboard?.topEntries)
 
    const chartData = topEntries?.length ? createEqualEntries(topEntries) : [];
  
    function createEqualEntries(){
        let currentMonthEntries = Object.values(topEntries[0].entries)
        let prevMonthEntries = Object.values(topEntries[1].entries)
 
    if(currentMonthEntries.length > prevMonthEntries.length){
            let diff = currentMonthEntries.length - prevMonthEntries.length
            for(let i=0; i<diff; i++){
                prevMonthEntries.push(0)
            }
        }else if(currentMonthEntries.length < prevMonthEntries.length){
            let diff = prevMonthEntries.length - currentMonthEntries.length
            for(let i=0; i<diff; i++){
                currentMonthEntries.push(0)
            }
        }

        return topEntries.map((entry, index) => {
            return {
                name: entry.month,
                data: index === 0 ? currentMonthEntries : prevMonthEntries
            }
        });
    }

    const chartOptions = {
        chart: {
            height: 420,
            type: 'area',
            width: 500,
            fontFamily: 'Inter, sans-serif',
            foreColor: '#6B7280',
            toolbar: {
                show: true,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                enabled: true,
                opacityFrom: 0.45,
                opacityTo: 0,
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            style: {
                fontSize: '14px',
                fontFamily: 'Inter, sans-serif',
            },
        },
        grid: {
            show: true,
            borderColor: '#F3F4F6',
            strokeDashArray: 1,
            padding: {
                left: 35,
                bottom: 15,
            },
        },

        markers: {
            size: 5,
            strokeColors: '#ffffff',
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },

        xaxis: {
            categories: Object.keys(topEntries)?.length > 0 ? Object.keys(topEntries[0]?.entries) : [],
            labels: {
                style: {
                    colors: ['#6B7280'],
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#F3F4F6',
            },
            axisTicks: {
                color: '#F3F4F6',
            },
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: '#F3F4F6',
                    width: 1,
                    dashArray: 10,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#6B7280'],
                    fontSize: '14px',
                    fontWeight: 500,
                }
            },
            title: {
                text: 'Entry Count',
                style: {
                    color: '#6B7280',
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
        },
        title: {
            text: 'Total Entries',
            align: 'left',
            style: {
                fontSize: '25px',
                color: 'rgb(30, 29, 29)',
                padding: '0px 0px 0px 40px'
            },
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    xaxis: {
                        labels: {
                            show: false,
                        },
                    },
                },
            },
        ],
        legend: {
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter, sans-serif',
            labels: {
                colors: ['#6B7280'],
            },
            itemMargin: {
                horizontal: 10,
            },
        },
        series: chartData,
    };

    return (
        <>
            

                <IonCard className="ion-card-white" style={{marginTop: '120px'}}>
                    <IonCardContent style={{width: '100%', overflowX: 'scroll'}}>
                        {topEntries?.length ?  ( <Chart options={chartOptions} series={chartOptions.series}
                               type="area" height={350}/>) : (<Spinner />)}
                       
                    </IonCardContent>
                </IonCard>
            


            

        </>
    )
}


export default CurrentPreviousEntriesChart;