import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

import {LoginPage} from './pages/Login';
import {useEffect, useState} from 'react';
import {supabase} from './helpers/sbClient';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css'
import './Utility.css'
import AddEntry from "./pages/AddEntry";
import {AuthProvider} from "./hooks/useAuth";
import Dashboard from "./pages/Dashboard";

setupIonicReact({
  rippleEffect: true,
  mode: 'ios',
});

const App = () => {
    const [session, setSession] = useState(null);
    useEffect(() => {
        setSession(supabase.auth.session());
        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });
    }, [session]);
    return (
        <IonApp>
            <IonReactRouter>
                <AuthProvider>
                    <IonRouterOutlet>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return session ? <Redirect to="/add-entry"/> : <LoginPage/>;
                            }}
                        />
                        <Route exact path="/add-entry"
                               render={() => {
                                   return session ? <AddEntry/> : <Redirect to="/"/>;
                               }}

                        />
                        <Route exact path="/dashboard"
                               render={() => {
                                   return session ? <Dashboard/> : <Redirect to="/"/>;
                               }}

                        />
                    </IonRouterOutlet>
                </AuthProvider>
            </IonReactRouter>
        </IonApp>
    )
};

export default App;
