import {createAsyncThunk} from '@reduxjs/toolkit'
import {fetchAPI} from "../../../helpers/fetchAPI";

export const addNotification = createAsyncThunk(
    'notification/addNotification',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/notification/add`, payload)
    }
)
export const getNotifications = createAsyncThunk(
    'notification/getNotifications',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/notification/list`, payload)
    }
)

export const updateNotification = createAsyncThunk(
    'notification/updateNotification',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/notification/update`, payload)
    }
)

export const getNotificationById = createAsyncThunk(
    'notification/getNotificationById',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/notification/byId`, payload)
    }
)
