import {configureStore} from '@reduxjs/toolkit';

import appReducer from './features/app'
import roleReducer from './features/role'
import userReducer from './features/user/slice'
import notificationsReducer from './features/notifications/slice'
import entryReducer from './features/entry/slice'
import templateReducer from './features/template/slice'
import dashboardReducer from "./features/dashboard/slice";
export const store = configureStore({
    reducer: {
        role: roleReducer,
        app: appReducer,
        user: userReducer,
        notifications: notificationsReducer,
        entry: entryReducer,
        template: templateReducer,
        dashboard: dashboardReducer
    },
});
