import {IonSpinner} from "@ionic/react";

const Spinner = ({options}) => {
    return (
        <div className="spinner-wrap">
            <IonSpinner name="crescent" ></IonSpinner>
        </div>
    )
}

export default Spinner