import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {fetchAPI} from "../../helpers/fetchAPI";
import {sortData} from "../../helpers";

const initialState = {
    id:"",
    name:"",
    active:"",
    list:null
}

export const getRoles = createAsyncThunk(
    'role/getRoles',
    async () => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/role/list`)
    }
)
const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getRoles.fulfilled]:(state, {payload}) => {
            state.list = sortData(payload.data, 'accesslevel')
        },
    },
})


export default roleSlice.reducer
