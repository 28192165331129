import {setAlert, setLoading} from "../store/features/app";
import {slugify} from "./index";
import sha256 from 'crypto-js/sha256';
import {supabase} from "./sbClient";

const ENGAGR_BUCKET = process.env.REACT_APP_SUPABASE_BUCKET

const HASH_SECRET = process.env.REACT_APP_HASH_SECRET
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL

export const uploadToSB = async (options) => {
    const {dispatch, fileToUpload, companyName, entity, bucket = "engagr"} = options

    if (fileToUpload) {
        try {
            let fileKey = "";
            let hashedFileName = ""
            if (entity) {
                hashedFileName = sha256(
                    `${Date.now().toString()}${entity.id}${entity.email}${fileToUpload.name}`,
                    HASH_SECRET
                )

                fileKey = `${slugify(companyName)}/${hashedFileName}.${fileToUpload.name.split('.').pop()}`
            } else {
                hashedFileName = sha256(
                    `${Date.now().toString()}${fileToUpload.name}`,
                    HASH_SECRET
                )

                fileKey = `${slugify(companyName)}/${hashedFileName}.${fileToUpload.name.split('.').pop()}`
            }

            const {data, error} = await supabase.storage
                .from(ENGAGR_BUCKET)
                .upload(fileKey, fileToUpload)
            if (!error) {
                const fileUrl = `${SUPABASE_URL}/storage/v1/object/public/${ENGAGR_BUCKET}/${fileKey}`

                return {data: fileUrl, error: null}
            } else {
                return {error, data: null}
            }

        } catch (error) {

            dispatch(setAlert({
                    show: true,
                    type: "error",
                    content: "There has been an issue uploading the image"
                }
            ));
            dispatch(setLoading(false))
            return {error, data: null}

        }
    }

}



