import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getTopUsersWithDailyCount} from "../../store/features/dashboard/thunks";
import {IonCard, IonCardContent, IonCardHeader, IonGrid, IonLabel, IonRow} from "@ionic/react";
import Chart from "react-apexcharts";

const TopUsersWithDailyCount = ({options}) => {
    const dispatch = useDispatch();
    const {companyId} = options
    const topUsersWithDailyCount = useSelector(state => state.dashboard?.topUsersWithDailyCount)

    useEffect(() => {
        if (companyId) {
            dispatch(getTopUsersWithDailyCount({companyId}))
        }
    }, [dispatch, companyId])

    const chartOptions = {
        chart: {
            height: 420,
            type: 'area',
            fontFamily: 'Inter, sans-serif',
            foreColor: '#6B7280',
            toolbar: {
                show: true,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                enabled: true,
                opacityFrom: 0.45,
                opacityTo: 0,
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            style: {
                fontSize: '14px',
                fontFamily: 'Inter, sans-serif',
            },
        },
        grid: {
            show: true,
            borderColor: '#F3F4F6',
            strokeDashArray: 1,
            padding: {
                left: 35,
                bottom: 15,
            },
        },

        markers: {
            size: 5,
            strokeColors: '#ffffff',
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },

        xaxis: {
            categories: topUsersWithDailyCount.length > 0 ? Object.keys(topUsersWithDailyCount[0]?.entry_counts_by_date) : [],
            labels: {
                style: {
                    colors: ['#6B7280'],
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#F3F4F6',
            },
            axisTicks: {
                color: '#F3F4F6',
            },
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: '#F3F4F6',
                    width: 1,
                    dashArray: 10,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#6B7280'],
                    fontSize: '14px',
                    fontWeight: 500,
                }
            },
            title: {
                text: 'Entry Count',
                style: {
                    color: '#6B7280',
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
        },
        title: {
            text: 'Top Users by Entries',
            align: 'left',
            style: {
                fontSize: '16px',
            },
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    xaxis: {
                        labels: {
                            show: false,
                        },
                    },
                },
            },
        ],
        legend: {
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter, sans-serif',
            labels: {
                colors: ['#6B7280'],
            },
            itemMargin: {
                horizontal: 10,
            },
        },
        noData: {
            text: "No Users Yet",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                fontWeight: 'bold',
                fontSize: '14px',
            }
        },
        series: topUsersWithDailyCount?.map((item) => ({
            name: item.name,
            data: Object.values(item.entry_counts_by_date || []),
        })),

    };

    return (
        <>
            <IonCard className="ion-card-white">
                <IonCardHeader>
                    {/*<IonCardTitle>Previous month*/}
                    {/*    ({topUsersWithDailyCount?.previous?.reduce((n, {entry_count}) => n + entry_count, 0)} entries)*/}
                    {/*    to*/}
                    {/*    current*/}
                    {/*    ({momUserEntries?.current?.reduce((n, {entry_count}) => n + entry_count, 0)} entries)</IonCardTitle>*/}
                </IonCardHeader>

                <IonCardContent>

                        <Chart options={chartOptions} series={chartOptions.series} type="area" height={350}/>


                </IonCardContent>
            </IonCard>
        </>
    )
}

export default TopUsersWithDailyCount;