import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import React, { useRef} from "react";
import bannerImg from '../../assets/banner.png'
import {IonButton, IonIcon, IonCard} from "@ionic/react";
import { useSelector} from "react-redux";
import {camera} from "ionicons/icons";
const EntryCanvas = ({imageUrl, modifiedImageCB, reUploadImageCB}) => {
    const transformWrapperRef = useRef()
    const banner = useSelector(state => state.user?.company?.settings?.postimagebanner)
    const logoImgUrl = useSelector(state => state.user?.company?.logo)
    const getImage = async () => {
        const html2canvas = (await import('html2canvas')).default;

        const blob = await new Promise((resolve) => {
            html2canvas(transformWrapperRef.current, {useCORS: true}).then((canvas) =>
                canvas.toBlob(resolve, 'png', 0.1)
            );
        });

        const cDate = new Date()
        blob.lastModifiedDate = cDate.getTime();
        blob.name= `${cDate.getTime()}.png`;
        blob.dataURL = URL.createObjectURL(blob)
        modifiedImageCB(blob)
    }
    return (
        <>
        <IonCard className="photo-wrap" style={{position:"relative"}}>

            <div ref={transformWrapperRef} className="w-full mx-auto overflow-hidden canvas-container"
                 style={{width: '600px', height: '600px'}}>
                {logoImgUrl && <div style={{position: "absolute", top: 0, right: 0, zIndex: 999}}>
                    <img src={logoImgUrl} alt="test" width="100px"/>
                </div>}
                {banner && <div style={{width: '100%', position: "absolute", left: 0, bottom: -3, zIndex: 999}}>
                    <img src={bannerImg} alt="banner" className="width-1-1"/>
                </div>}
                <TransformWrapper >
                    <TransformComponent>
                        <img src={imageUrl} alt="test"/>
                    </TransformComponent>
                </TransformWrapper>

            </div>
            <div className="canvas-container" style={{width: '600px'}}>
                <IonButton type="button" className="r-rds-40 hidden" expand="block" shape="undefined" size="large" color="danger"
                           onClick={getImage}> Next</IonButton>


              <div className="alert-button-group sc-ion-alert-ios">
              <IonButton onClick={reUploadImageCB} type="button" class="alert-button ion-focusable ion-activatable alert-button-role-cancel sc-ion-alert-ios" size="large" color="none" tabindex="0">
              <span className="alert-button-inner sc-ion-alert-ios">
               <div className="ion-hide-xl-up">Camera</div>
               <div className="ion-hide-xl-down">Upload Photo</div>
              </span>
              </IonButton>
              <IonButton onClick={getImage} type="button" class="alert-button ion-focusable ion-activatable alert-button-role-confirm sc-ion-alert-ios" size="large" color="none" tabindex="0">
               <span className="alert-button-inner sc-ion-alert-ios" style={{fontWeight: '900'}}>Next</span>
              </IonButton>
              </div>

            </div>
          </IonCard>

        </>

    )
}

export default EntryCanvas
