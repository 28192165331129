import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    alert: {
        show: false,
        type: "",
        content: ""
    },
    deactivate:{
        show: false,
        entity: "",
        action:"",
        id:"",
        content: ""
    },
    loading: false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAlert: (state, {payload}) => {
            const {show, type, content} = payload;
            return {...state, alert:{ show, type, content} }
        },
        setLoading:(state,{payload})=>{

            return {...state, loading: payload}
        },
        setDeactivate: (state, {payload}) => {
            const {show, entity, action, id, content} = payload;
            return {...state, deactivate:{show, entity, action, id, content}}
        },
    },
})

export const {setAlert, setLoading,setDeactivate} = appSlice.actions
export default appSlice.reducer