import {
    IonBadge,
    IonButton,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonRow,
    IonToggle,
    IonLabel,
    IonIcon,
    IonSelect,
    IonContent,
    IonSelectOption,
    useIonAlert,
    createAnimation
} from '@ionic/react';
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {warningOutline} from "ionicons/icons";
import {useDispatch, useSelector} from "react-redux";
import UploadEntryImage from "../components/entry/UploadEntryImage";
import EntryCanvas from "../components/entry/EntryCanvas";

import EntryPreview from "../components/entry/EntryPreview";

import {useForm} from "react-hook-form";
import {addEntry, postToFBPage, sendEmail, sendSMS} from "../store/features/entry/thunks";
import {setAlert, setLoading} from "../store/features/app";

import {getTemplateById} from "../store/features/template/thunks";
import {uploadToSB} from "../helpers/sbStorage";
import AppLayout from "../components/Layout";

import {mergeTagsToHtml} from "../helpers";
import {addNotification} from "../store/features/notifications/thunks";
import SubmitStatus from "../components/entry/SubmitStatus";
import SubmitDialog from "../components/entry/SubmitDialog";
import LoadingSpinner from "../helpers/Loader";

const AddEntry = () => {
    const dispatch = useDispatch();

    const {register, handleSubmit, reset, formState: {errors}} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });
    const formRef = useRef(null)
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [modifiedImage, setModifiedImage] = useState(null);
    const [formValues, setFormValues] = useState(null)
    const [formSubmitting, setFormSubmitting] = useState(null)
    const [formSubmitted, setFormSubmitted] = useState(null)
    const [postOnFacebook, setPostOnFacebook] = useState(false);

    const [useDefaultPostingOptions, setUseDefaultPostingOptions] = useState(true);
    const [submitEntryStatus, setSubmitEntryStatus] = useState("pending")
    const [mergeTags, setMergeTags] = useState(null)

    const [entryProcessed, setEntryProcessed] = useState(false)

    const [emailcontent, setEmailContent] = useState(null)
    const [sendEmailStatus, setSendEmailStatus] = useState("pending")

    const [smscontent, setSmscontent] = useState(null)
    const [sendSmsStatus, setSendSmsStatus] = useState("pending")

    const [facebookContent, SetFacebookContent] = useState(null)
    const [postOnFBStatus, setPostOnFBStatus] = useState("pending")
    const [postoptions, setPostoptions] = useState(null)
    const [notifications, setNotifications] = useState({})
    const [showScheduler, setShowScheduler] = useState(false)

    const [showSubmitStatus, setShowSubmitStatus] = useState(false)

    const user = useSelector(state => state.user)
    const entry = useSelector(state => state.entry)
    const settings = useSelector(state => state.user?.company?.settings)
    const loading = useSelector(state => state.app?.loading)
    const template = useSelector(state => state.template)
    const company = useSelector(state => state.user?.company)
    const [canAddEntry, setCanAddEntry] = useState(false)
    const [isReUploadImage, setIsReUploadImage] = useState(false)
  const fbDefaultPostOptions = settings ? JSON.parse(settings.postoptions) : {}

    const [presentAlert] = useIonAlert();

    const uploadImageCB = (uImage) => {
        setUploadedImage(uImage);
        setCurrentStepIndex(1)
    }

    const reUploadImageCB = () => {
        presentAlert({
            header: 'You will lose current adjustments with this action. Are you sure you want to continue?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        setIsReUploadImage(true)
                        setModifiedImage(null)
                        setCurrentStepIndex(0)
                    },
                },
            ],
        })

    }
    const modifiedImageCB = (mImage) => {

        if (mImage) {
            setCurrentStepIndex(currentStepIndex + 1)
            setModifiedImage(mImage)
        }

    }

    const onSubmit = async (data) => {

        data.postonfacebook = postOnFacebook
        data.userid = user.id
        data.companyid = company.id

        setFormValues(data)
        await submitForm(data)
    }

    useEffect(() => {
        dispatch(setLoading(false))
    }, [])

    useLayoutEffect(() => {

        dispatch(setLoading(true))
    }, [])
    useEffect(() => {
        if (settings) {
            if (settings.defaulttemplateid && settings.smstemplate) {
                setCanAddEntry(true)
            } else {
                setCanAddEntry(false)
            }
            if (settings?.defaulttemplateid && !template?.id) {

                dispatch(getTemplateById({id: settings.defaulttemplateid})).then(res => {

                    if (res.payload.error) {
                        console.log(res.payload.error)
                    }
                })
            }

        }

    }, [settings, template, dispatch])

    useEffect(() => {

        if (submitEntryStatus === "success") {

            if (mergeTags && template.contenthtml) {
                const mergedTemplate = mergeTagsToHtml(template.contenthtml, mergeTags)

                setEmailContent(mergedTemplate);
            }

            if (settings) {
                const {smstemplate, facebooktemplate} = settings
                if (smstemplate) {
                    setSmscontent(mergeTagsToHtml(smstemplate, mergeTags));
                }

                if (postOnFacebook && facebooktemplate) {
                    SetFacebookContent(mergeTagsToHtml(facebooktemplate, mergeTags));
                }
            }

        }
    }, [submitEntryStatus])

    useEffect(() => {

        if (emailcontent) {

            const data = {
                user,
                companyName: company.name,
                fromEmail: 'noreply@engagr.com.au',
                toEmail: formValues.email,
                subject: template.subject,
                subTitle: template.subtitle,
                html: emailcontent
            }
            const dispatchSendEmail = dispatch(sendEmail({data}))

            dispatchSendEmail.then(res => {

                if (res.payload.error) {
                    setSendEmailStatus("error")
                    setNotifications(state => ({...state, ...{emailsent: false, emailcontent}}))
                } else {
                    setSendEmailStatus("success")
                    setNotifications(state => ({...state, ...{emailsent: true, emailcontent}}))
                }

            })


        }
    }, [emailcontent])

    useEffect(() => {
        if (smscontent) {

            const dispatchSMS = dispatch(sendSMS({
                data: {
                    sendTo: formValues.contactnumber,
                    content: smscontent
                }
            }))

            dispatchSMS.then(res => {
                if (res.payload.error) {
                    setSendSmsStatus("error")
                    setNotifications(state => ({...state, ...{smssent: false, smscontent}}))
                } else {
                    setSendSmsStatus("success")
                    setNotifications(state => ({...state, ...{smssent: true, smscontent}}))
                }

            })
        }
    }, [smscontent])

    useEffect(() => {
        if (facebookContent) {

            // post to facebook
            const dispatchPostToFBPage = dispatch(postToFBPage({
                data: {
                    imageUrl: formValues.photo,
                    content: facebookContent,
                    pageId: settings.facebookpageid,
                    hourstodelay: formValues.hourstodelay,
                    accessToken: settings.pageaccesstoken
                }
            }))

            dispatchPostToFBPage.then(res => {

                if (res.payload.hasOwnProperty("error")) {
                    setPostOnFBStatus("error")
                    setNotifications(state => ({...state, ...{fbposted: false, fbpostcontent: facebookContent}}))
                } else {
                    setPostOnFBStatus("success")
                    setNotifications(state => ({...state, ...{fbposted: true, fbpostcontent: facebookContent}}))
                }

            })
        }
    }, [facebookContent])

    useEffect(() => {
        if (useDefaultPostingOptions || postoptions) {
            setPostOnFacebook(true)
        }
    }, [useDefaultPostingOptions, postoptions])

    useEffect(() => {
        if (sendSmsStatus !== 'pending' && sendEmailStatus !== 'pending') {
            if (postOnFacebook) {
                if (postOnFBStatus !== 'pending') {
                    setEntryProcessed(true)
                }
            } else {
                setEntryProcessed(true)
            }

        }
    }, [sendSmsStatus, sendEmailStatus, postOnFacebook, postOnFBStatus])

    useEffect(() => {
        if (entryProcessed) {
            const data = {
                entryid: entry?.id,
                companyid: company?.id,
                userId: user?.id,
                ...notifications
            }

            dispatch(addNotification({data})).then(res => {
                setFormSubmitted(true)
            })
        }

    }, [entryProcessed])

    useEffect(() => {
        if (currentStepIndex === 0) {
            setUploadedImage(null)
        }
    }, [currentStepIndex])

    const onRGFChangeCB = (value) => {

        if (value) {
            const valueObject = JSON.parse(value)

            setPostoptions(value)
            setShowScheduler(valueObject.id === 'schedule-post')
        }

    }

    const uploadTheFile = async (values) => {
        if (modifiedImage) {

            const uploadedFile = await uploadToSB({
                companyName: company.name,
                entity: {
                    id: user.id,
                    email: values.email,
                },
                bucket: "engagr",
                fileToUpload: modifiedImage,
                dispatch
            })

            if (uploadedFile.data) {
                return uploadedFile.data;
            }

            if (uploadedFile.error) {
                dispatch(setAlert({
                    show: true,
                    type: "warning",
                    content: "Failed to upload the image please try again after sometime"
                }));
                return false
            }
        }
    }

    const processMergeTags = (values) => {

        if (template.mergetags) {
            const mergeTagsToProcess = JSON.parse(template.mergetags)

            const {company, user, postOnFacebook, postOnInstagram, leadSource, ...customer} = values

            setMergeTags({company: mergeTagsToProcess.company, customer})
        }

    }

    const handleBackClick = () => {

        setCurrentStepIndex(currentStepIndex - 1)
    }
    const handleAddAnotherEntryClick = () => {
        window.location.href = '/add-entry'
    }
    const submitForm = async (data) => {

        // setCurrentStepIndex(currentStepIndex + 1)
        setFormSubmitting(true)
        setShowSubmitStatus(true)
        const uploadUrl = await uploadTheFile(data)
        if (uploadUrl) data.photo = uploadUrl
        await processMergeTags(data);

        dispatch(addEntry({data})).then(res => {

            if (res.payload.error) {
                setSubmitEntryStatus("error")
            } else {
                setSubmitEntryStatus("success")
            }
        })
    }
    const handleNextClick = async () => {

        if (currentStepIndex === 2) {
            if (formRef.current) {
                formRef.current.dispatchEvent(
                    new Event('submit', {cancelable: true, bubbles: true})
                )
            }
        } else {
            setCurrentStepIndex(currentStepIndex + 1)
        }
    }

    const animation = createAnimation()
   .addElement(document.querySelector('.ionRow-2'))
   .duration(1500)
   .iterations(Infinity)
   .fromTo('transform', 'translateX(0px)', 'translateX(100px)')
   .fromTo('opacity', '1', '0.2');

    return (
        <AppLayout title="New Entry" fullscreen className="ion-padding ion-login-bg">
            {!loading ? <>
                    <IonContent className="trans-bg">
                        <IonGrid style={{height: "100%", padding:"0"}}>
                            <IonRow style={{height: "100%"}}
                                    className={`ionRow-${currentStepIndex}`}>
                                <ion-col>
                                    {currentStepIndex === 0 &&
                                        <UploadEntryImage isReUploadImage={isReUploadImage} uploadImageCB={uploadImageCB}/>}
                                    {currentStepIndex === 1 &&
                                        <EntryCanvas imageUrl={uploadedImage} modifiedImageCB={modifiedImageCB}
                                                     reUploadImageCB={reUploadImageCB}/>}
                                    {currentStepIndex === 2 && (
                                        <>
                                            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}
                                                  className="submission-screen-form">
                                                <IonGrid>
                                                    <IonRow class="hidden">
                                                        <IonCol class="ion-text-center">
                                                            <h3>Submission Details</h3>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem className={`input-${errors.product && 'error'}`}>
                                                                {errors.product &&
                                                                    <IonIcon
                                                                        class="icon-error"
                                                                        style={{
                                                                            width: "17px",
                                                                            height: "17px",
                                                                            color: "#c00",
                                                                            marginRight: "2px"
                                                                        }}
                                                                        icon={warningOutline}
                                                                    />
                                                                }
                                                                <IonLabel> Product name:</IonLabel>
                                                                <IonInput
                                                                    position="stacked"
                                                                    type="text"
                                                                    name="product"
                                                                    placeholder=""
                                                                    {...register("product", {required: true})}
                                                                />
                                                                {errors.product &&
                                                                    <IonBadge color="danger" class="badge-error hidden">Product
                                                                        is required</IonBadge>}
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>


                                                            <IonItem className={`input-${errors.condition && 'error'}`}>
                                                                {errors.condition &&
                                                                    <IonIcon
                                                                        class="icon-error"
                                                                        style={{
                                                                            width: "17px",
                                                                            height: "17px",
                                                                            color: "#c00",
                                                                            marginRight: "2px"
                                                                        }}
                                                                        icon={warningOutline}
                                                                    />
                                                                }
                                                                <IonLabel> Condition:</IonLabel>
                                                                <IonSelect
                                                                    name="condition"
                                                                    placeholder="Select condition"
                                                                    okText="Ok"
                                                                    cancelText="Dismiss"
                                                                    {...register("condition", {required: true})}
                                                                >
                                                                    <IonSelectOption value="new">New</IonSelectOption>
                                                                    <IonSelectOption value="used">Used</IonSelectOption>
                                                                    <IonSelectOption value="demo">Demo</IonSelectOption>
                                                                </IonSelect>
                                                                {errors.condition &&
                                                                    <IonBadge color="danger" class="badge-error hidden">Condition
                                                                        is
                                                                        required</IonBadge>}
                                                            </IonItem>

                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem className={`input-${errors.firstname && 'error'}`}>
                                                                {errors.firstname &&
                                                                    <IonIcon
                                                                        class="icon-error"
                                                                        style={{
                                                                            width: "17px",
                                                                            height: "17px",
                                                                            color: "#c00",
                                                                            marginRight: "2px"
                                                                        }}
                                                                        icon={warningOutline}
                                                                    />
                                                                }
                                                                <IonLabel>First name:</IonLabel>
                                                                <IonInput
                                                                    position="stacked"
                                                                    type="text"
                                                                    name="firstname"
                                                                    placeholder=""
                                                                    {...register("firstname", {required: true})} />
                                                                {errors.firstname &&
                                                                    <IonBadge color="danger" class="badge-error hidden">First
                                                                        name is required</IonBadge>}
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem className={`input-${errors.lastname && 'error'}`}>
                                                                {errors.lastname &&
                                                                    <IonIcon
                                                                        class="icon-error"
                                                                        style={{
                                                                            width: "17px",
                                                                            height: "17px",
                                                                            color: "#c00",
                                                                            marginRight: "2px"
                                                                        }}
                                                                        icon={warningOutline}
                                                                    />
                                                                }
                                                                <IonLabel>Last name:</IonLabel>
                                                                <IonInput
                                                                    position="stacked"
                                                                    type="text"
                                                                    name="lastname"
                                                                    placeholder=""
                                                                    {...register("lastname", {required: true})}
                                                                />
                                                                {errors.lastname &&
                                                                    <IonBadge color="danger" class="badge-error hidden">Last
                                                                        name is required</IonBadge>}
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem className={`input-${errors.email && 'error'}`}>
                                                                {errors.email &&
                                                                    <IonIcon
                                                                        class="icon-error"
                                                                        style={{
                                                                            width: "17px",
                                                                            height: "17px",
                                                                            color: "#c00",
                                                                            marginRight: "2px"
                                                                        }}
                                                                        icon={warningOutline}
                                                                    />
                                                                }
                                                                <IonLabel>Email address:</IonLabel>
                                                                <IonInput
                                                                    position="stacked"
                                                                    type="text"
                                                                    name="email"
                                                                    placeholder=""
                                                                    {...register("email", {
                                                                        required: true,
                                                                        pattern: {
                                                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                            message: 'Please enter a valid email',
                                                                        }
                                                                    })}
                                                                />
                                                                {errors.email &&
                                                                    <IonBadge color="danger" class="badge-error hidden">Valid
                                                                        email is required</IonBadge>}
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem className={`input-${errors.contactnumber && 'error'}`}>
                                                                {errors.contactnumber &&
                                                                    <IonIcon
                                                                        class="icon-error"
                                                                        style={{
                                                                            width: "17px",
                                                                            height: "17px",
                                                                            color: "#c00",
                                                                            marginRight: "2px"
                                                                        }}
                                                                        icon={warningOutline}
                                                                    />
                                                                }
                                                                <IonLabel>Contact Number:</IonLabel>
                                                                <IonInput
                                                                    position="stacked"
                                                                    type="tel"
                                                                    name="contactnumber"
                                                                    placeholder=""
                                                                    {...register("contactnumber", {required: true})}
                                                                />
                                                                {errors.contactnumber &&
                                                                    <IonBadge color="danger" class="badge-error hidden">Contact
                                                                        number is required</IonBadge>}
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem lines="none">
                                                                <IonLabel>
                                                                    Use default facebook post options
                                                                    <p>Current default option - <b>{JSON.parse(settings.postoptions).label}</b></p>
                                                                </IonLabel>
                                                                <IonToggle checked={useDefaultPostingOptions}
                                                                           onIonChange={e => setUseDefaultPostingOptions(e.detail.checked)}/>

                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    {!useDefaultPostingOptions && <>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines="none">
                                                                    <IonLabel> Facebook post options</IonLabel>
                                                                    <IonSelect
                                                                        name="postoptions"
                                                                        placeholder="Select options"
                                                                        okText="Ok"
                                                                        cancelText="Dismiss"
                                                                        onIonChange={e => onRGFChangeCB(e.detail.value)}
                                                                    >
                                                                        <IonSelectOption value={JSON.stringify({
                                                                            id: "instant-post",
                                                                            label: "Instant post"
                                                                        })}>Instant post</IonSelectOption>
                                                                        <IonSelectOption value={JSON.stringify({
                                                                            id: "schedule-post",
                                                                            label: "Schedule post"
                                                                        })}>Schedule Post</IonSelectOption>
                                                                    </IonSelect>
                                                                </IonItem>

                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                {showScheduler && <IonItem lines="none">
                                                                    <IonLabel>Hours to delay posting</IonLabel>
                                                                    <IonSelect
                                                                        name="hourstodelay"
                                                                        okText="Ok"
                                                                        cancelText="Dismiss"
                                                                        placeholder="Select hours"
                                                                    >
                                                                        <IonSelectOption value="1">1 hour</IonSelectOption>
                                                                        <IonSelectOption value="2">2 hours</IonSelectOption>
                                                                        <IonSelectOption value="3">3 hours</IonSelectOption>
                                                                        <IonSelectOption value="4">4 hours</IonSelectOption>
                                                                    </IonSelect>
                                                                </IonItem>}
                                                            </IonCol>
                                                        </IonRow>
                                                    </>
                                                    }

                                                    <IonRow>
                                                        <IonCol class="ion-text-right ion-padding-top">
                                                            <IonButton type="button" color="primary" size="large"
                                                                       expand="block"
                                                                       className="r-rds-40"
                                                                       disabled={!uploadedImage || formSubmitting}
                                                                       onClick={handleNextClick}>Submit</IonButton>
                                                        </IonCol>
                                                    </IonRow>

                                                </IonGrid>
                                            </form>
                                            <SubmitDialog showSubmitStatus={showSubmitStatus}
                                                          submitEntryStatus={submitEntryStatus}
                                                          smsStatus={sendSmsStatus}
                                                          emailStatus={sendEmailStatus}
                                                          fbStatus={postOnFBStatus}
                                                          formSubmitted={formSubmitted}
                                                          handleAddAnotherEntryClick={handleAddAnotherEntryClick}
                                            /></>
                                    )}

                                </ion-col>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </>
                :
                <LoadingSpinner/>
            }
        </AppLayout>

    );
};

export default AddEntry;
