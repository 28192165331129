import {createAsyncThunk} from '@reduxjs/toolkit'
import {fetchAPI} from "../../../helpers/fetchAPI";

export const getLeaderboard = createAsyncThunk(
    'dashboard/leaderboard',
    async (payload) => {

        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/dashboard/topUsers`, payload)
    }
)

export const getTopUsersWithDailyCount = createAsyncThunk(
    'dashboard/topUsersWithDailyCount',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/dashboard/topUsersWithDailyCount`, payload)
    }
)

export const getMOMUserEntries = createAsyncThunk(
    'dashboard/momUserEntries',
    async (payload) => {
        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/dashboard/momUserEntries`, payload)
    }
)

export const getCurrentAndLastMonthTopEntries = createAsyncThunk(
    'dashboard/currentAndLastMonthTopEntries',
    async (payload) => {

        return await fetchAPI(`${process.env.REACT_APP_ENGAGR_API}/dashboard/currentAndLastMonthTopEntries`, payload)
    }
)