import {createSlice} from '@reduxjs/toolkit'
import {addNotification, getNotifications, getNotificationById} from './thunks'

const notificationInitialState = {
    id: "",
    emailsent: "",
    emailcontent: "",
    smssent: "",
    smscontent: "",
    fbscheduled: "",
    fbscheduledOn: "",
    fbpostcontent: "",
    fbposted: "",
    instascheduled: "",
    instascheduledOn: "",
    instapostcontent: "",
    instaposted: "",
    entryid: "",
    entry: {},
    active: "",
    createdon: "",
    createdby: "",
    updatedon: "",
    updatedby: "",
    list: null,
    isLoading: false

}
const notificationSlice = createSlice({
    name: 'notification',
    initialState: notificationInitialState,
    reducers: {},
    extraReducers: {
        [getNotifications.fulfilled]: (state, {payload}) => {

            state.list = payload.data
        },
        [getNotificationById.fulfilled]: (state, {payload}) => {
            const data = payload.data[0]
            return {...state, ...data}
        },
        [addNotification.fulfilled]: (state,{payload}) => {
            return {...state, ...payload.data}
        },
    },
})

export default notificationSlice.reducer