import {createSlice} from '@reduxjs/toolkit'
import {getCurrentAndLastMonthTopEntries, getLeaderboard, getMOMUserEntries, getTopUsersWithDailyCount} from './thunks'


const dashboardInitialState = {
    leaderboards:[],
    momUserEntries:[],
    topUsersWithDailyCount:[],
    topEntries:[],
    isLoading: false,

}
const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: dashboardInitialState,
    reducers: {
    },
    extraReducers: {
        [getLeaderboard.fulfilled]: (state, {payload}) => {
            state.leaderboards = payload.data

        },
        [getTopUsersWithDailyCount.fulfilled]: (state, {payload}) => {
            state.topUsersWithDailyCount = payload.data
        },

        [getMOMUserEntries.fulfilled]: (state, {payload}) => {
            const {current, previous} = payload
            state.momUserEntries = {current,previous}
        },
        [getCurrentAndLastMonthTopEntries.fulfilled]: (state, {payload}) => {

            if(payload.data){
                state.topEntries = payload.data
            }
        },

    },
})

export default dashboardSlice.reducer
