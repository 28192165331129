import React from "react";
import engagrLogo from '../assets/engagr.svg'
export default function LoadingSpinner() {
  return (
    <div className="ion-align-items-center loader-container">
      <div className="loader-inner">
       <img src={engagrLogo} width="80" height="90" />
      </div>
    </div>
  );
}
