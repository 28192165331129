import React, {useEffect, useRef, useState} from 'react';
import {
    IonButton,
    IonModal,
    IonRow,
    IonCol
} from '@ionic/react';
import SubmitStatus from "./SubmitStatus";

const SubmitDialog = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const modal = useRef(null);
    const {
        showSubmitStatus,
        submitEntryStatus,
        smsStatus,
        emailStatus,
        fbStatus,
        formSubmitted,
        handleAddAnotherEntryClick
    } = props

    const handleAddAnother = () => {
        // modal.current?.dismiss();
        setIsOpen(false)
        handleAddAnotherEntryClick()
    }
    useEffect(() => {
        if (showSubmitStatus !== null) {
            setIsOpen(showSubmitStatus)
        }

    }, [showSubmitStatus])
    return (
        <IonModal id="submit-modal" ref={modal} isOpen={isOpen} backdropDismiss={false}>
            <div className="submit-wrapper">
                <div>
                <IonRow>
                  <IonCol class="logo-item-header ion-text-center">
                    <img src="./assets/engagr-blk.svg" width="80" height="90" />
                  </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol class="ion-padding ion-background-black">
                        <SubmitStatus key={'entry'} status={submitEntryStatus}
                                      processingText={'Submitting entry details'}
                                      processedText={'Entry details has been submitted'}
                                      errorText={'Failed to submit entry'}
                        />

                        <SubmitStatus key={"email"}
                                      status={emailStatus}
                                      processingText={'Sending email'}
                                      processedText={'Email has ben sent'}
                                      errorText={'Failed to send email'}
                        />

                        <SubmitStatus key={'sms'} status={smsStatus}
                                      processingText={'Sending SMS'}
                                      processedText={'SMS has been sent'}
                                      errorText={'Failed to send sms'}

                        />
                        <SubmitStatus key={"fb"}
                                      status={fbStatus}
                                      processingText={'Posting to facebook page'}
                                      processedText={'Finished posting to facebook page'}
                                      errorText={'Failed to post to facebook page'}
                        />
                    </IonCol>
                    </IonRow>
                    {formSubmitted && <IonButton type="button" className="add-another-btn" expand="block" shape="undefined"
                               onClick={handleAddAnother}>Add Another Entry</IonButton>}

                </div>
            </div>
        </IonModal>
    )
}

export default SubmitDialog
