import {useState} from 'react';
import {
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonToast,

} from '@ionic/react';
import engagrWHSVG from '../assets/engagr-wh.svg'
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import {supabase} from '../helpers/sbClient';
import {getUserById} from "../store/features/user/thunks";
import {useDispatch} from "react-redux";
import {setLoading} from "../store/features/app";

export function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [showToast] = useIonToast();
    const dispatch = useDispatch()
    const handleLogin = async (e) => {
        e.preventDefault();
        await dispatch(setLoading(true));
        try {
            if (!email || !password) {
                await showToast({message: "Both email and password are required", duration: 5000});
            }else{
                const {error, session} = await supabase.auth.signIn({email, password});
                if (!error) {
                    await dispatch(getUserById(session.user.id)).then(async res => {
                        await dispatch(setLoading(false));
                    })
                } else {
                    await showToast({message: error.message, duration: 5000});
                }
            }

        } catch (e) {
            await showToast({message: e.error_description || e.message, duration: 5000});
        } finally {
            await dispatch(setLoading(false));
        }
    };
    return (
      <IonPage>
      <IonHeader class="hidden">
        <IonToolbar>
          <IonTitle>Sign in</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="ion-padding ion-login-bg">
      <IonGrid style={{ height: "100%" }}>
        <IonRow class="ion-align-items-center" style={{ height: "100%" }}>

        <IonGrid>
         <IonRow class="login-screen-logo">
          <IonCol class="ion-padding ion-text-center">
            <img src={engagrWHSVG} width="80" height="90" />
          </IonCol>
        </IonRow>


        <form onSubmit={handleLogin} style={{ width: "100%" }} className="login-screen-form">





            <IonItem>
            <IonLabel>Email:</IonLabel>
            <IonInput position="stacked" color="primary"
            value={email}
            name="email"
            onIonChange={(e) => setEmail(e.detail.value ?? '')}
            type="email"
                >
            </IonInput>
            </IonItem>



            <IonItem>
              <IonLabel>Password:</IonLabel>
              <IonInput position="stacked" color="primary"
              value={password}
              name="email"
              onIonChange={(e) => setPassword(e.detail.value ?? '')}
              type="password"
                >
              </IonInput>
            </IonItem>

          <IonRow>
            <IonCol class="ion-text-center">
              <p style={{ fontSize: "small" }}>
                  By clicking login you agree to our <a href="#">Policy</a>
              </p>
              <IonButton expand="block" type="submit" shape="round" size="medium" class="ion-activatable ripple-parent primary">
              Login
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
              </IonButton>
            </IonCol>
          </IonRow>
          </form>
          </IonGrid>


         </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
    );
}
