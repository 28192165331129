import {createSlice} from '@reduxjs/toolkit'
import {addTemplate, getTemplates, getTemplateById} from './thunks'

const templateInitialState = {
    id:"",
    name:"",
    subject:"",
    subtitle:"",
    screenshot:"",
    contentjson:"",
    contentmjml:"",
    contenthtml:"",
    mergetags:"",
    product:"",
    companyid:"",
    createdon:"",
    createdby:"",
    updatedon:"",
    updatedby:"",
    active: true,
    list: null,
    isLoading: false

}
const templateSlice = createSlice({
    name: 'template',
    initialState: templateInitialState,
    reducers: {},
    extraReducers: {
        [getTemplates.fulfilled]: (state, {payload}) => {
            state.list = payload.data
            state.isLoading = false
        },
        [getTemplateById.fulfilled]: (state, {payload}) => {
            const data = payload.data[0]
            return {...state, ...data}
        },
        [addTemplate.fulfilled]: (state) => {
            state.isLoading = false
        }
    },
})

export default templateSlice.reducer

