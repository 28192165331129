import {useDispatch, useSelector} from "react-redux";
// import Chart from 'react-apexcharts';
import {
    // IonCard,
    // IonCardContent,
    // IonCardHeader,
    // IonCardSubtitle,
    // IonCardTitle,
    IonContent,
    IonGrid,
    IonRow
} from '@ionic/react';
import AppLayout from "../components/Layout";
import LoadingSpinner from "../helpers/Loader";
import React, {useEffect, useState} from "react";

import CurrentPreviousEntriesChart from "../components/dashboard/CurrentPreviousEntriesChart";
import Leaderboard from "../components/dashboard/Leaderboard";
import TopUsersWithDailyCount from "../components/dashboard/TopUsersWithDailyCount";
import CurrentMonthEntriesByUser from "../components/entry/CurrentMonthEntriesByUser";

const Dashboard = () => {

    const [companyId, setCompanyId] = useState("28d5a1f1-39ff-4fa7-b33a-14e4e440143d");
    const [userId, setUserId] = useState("d05bec50-6b20-41ff-b340-b6d11143b51f");
    
    const loading = useSelector(state => state.app?.loading)
    


    const [display, setDisplay] = useState(false);




    useEffect(() => {
        setTimeout(() => setDisplay(true), 3000);
    }, [])

    return (

        <AppLayout title="New Entry" fullscreen className="ion-padding">
            {!loading && display && companyId ? <>
                <IonContent className="trans-bg" style={{textAlign: "left"}}>
                    <IonGrid>
                        <IonRow>
                            <ion-col>
                                <CurrentPreviousEntriesChart options={{companyId}}/>
                            </ion-col>
                        </IonRow>
                        <IonRow>
                            <ion-col>
                                <Leaderboard options={{companyId}}/>
                            </ion-col>
                        </IonRow>
                        <IonRow>
                            <ion-col>
                                <TopUsersWithDailyCount options={{companyId}}/>
                            </ion-col>
                        </IonRow>
                        <IonRow>
                            <ion-col>
                                <CurrentMonthEntriesByUser options={{userId}}/>
                            </ion-col>
                        </IonRow>
                    </IonGrid>

                </IonContent>
            </> : <LoadingSpinner/>}
        </AppLayout>
    );
}

export default Dashboard;