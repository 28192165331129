import {
    IonPage, IonContent, IonHeader, IonTitle, IonGrid, IonRow, IonCol,
    IonButton, useIonAlert, IonIcon
} from "@ionic/react";
import engagrWHSVG from '../assets/engagr-wh.svg'
import {useAuth} from "../hooks/useAuth";
import {homeOutline, reloadOutline} from "ionicons/icons";
import React from "react";
import {useSelector} from "react-redux";
import LoadingSpinner from "../helpers/Loader";
const AppLayout = ({children, title}) => {
    const auth = useAuth()
    const loading = useSelector(state=>state.app?.loading)
    const [presentAlert]  = useIonAlert();
    const resetPage = () => {
        presentAlert({
            header: 'You will lose the progress with this action. Are you sure you want to continue?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        window.location.href='/add-entry'

                    },
                },
            ],
        })
    }
    return (
        <IonPage>
            <IonHeader>
                    <IonGrid>
                        <IonRow>
                        <IonCol class="hdr-logo">
                          <img src={engagrWHSVG} width="80" height="80"
                             style={{
                              margin: "20px 10px 0",
                            }}
                          />
                        </IonCol>

                            <IonCol class="hidden">
                                <IonTitle>{title}</IonTitle>
                            </IonCol>
                            <IonCol class={"ion-text-right"}>
                                {auth.user && <IonButton class="transparent-bg" onClick = {()=>window.location.href='/dashboard'}>
                                    <IonIcon
                                        class="ion-home"
                                        style={{
                                            fontSize: "10px",
                                            width: "20px",
                                            height: "20px",
                                            padding: "0",

                                        }}
                                        icon={homeOutline}
                                    />
                                </IonButton>}

                                {auth.user && <IonButton class="transparent-bg" onClick = {resetPage}>
                                    <IonIcon
                                        class="ion-edit"
                                        style={{
                                            fontSize: "10px",
                                            width: "20px",
                                            height: "20px",
                                            padding: "0",

                                        }}
                                        icon={reloadOutline}
                                    />
                                </IonButton>}
                                {auth.user && <IonButton class="transparent-bg" onClick = {()=>{ auth.signOut()}}>
                                    Logout
                                </IonButton>}

                            </IonCol>
                        </IonRow>
                    </IonGrid>

            </IonHeader>
            <IonContent fullscreen className="ion-text-center ion-login-bg overflow-none">
                {children}
                {loading && <LoadingSpinner />}
            </IonContent>
        </IonPage>
    )
}

export default AppLayout
