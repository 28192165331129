import {useEffect, useState} from 'react';
import {IonCol, IonGrid, IonImg, IonRow} from '@ionic/react';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';


const UploadEntryImage = ({isReUploadImage, uploadImageCB}) =>{
    const [uploadedImage, setUploadedImage] = useState(null);
    const takePhoto = async () => {
        return await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100,
            promptLabelPhoto: 'Gallery',
            promptLabelPicture : 'Camera',
            promptLabelHeader : 'Cancel',
        });
    };

    useEffect(() => {
        if(isReUploadImage){
            handleTakePhoto()
        }
    },[isReUploadImage])

    const handleTakePhoto = async () => {

        const uploadedPhoto = await takePhoto()

        setUploadedImage(uploadedPhoto.webPath)
        uploadImageCB(uploadedPhoto.webPath)
    }

    function addBlur(e) {
     document.querySelector("#root").classList.add('blur');
    }
    function removeBlur(e) {
     document.querySelector("#root").classList.remove('blur');
    }

    return(
            <IonGrid onClick={handleTakePhoto}>
                <IonRow>
                    <IonCol onMouseOver={addBlur} onMouseOut={removeBlur}>
                        <a type="button" className="text-white cameraa">
                        <img src="./assets/cw--camera.svg" width="100" height="100" />
                        <div id="dot"><div className="ping"></div></div>
                        <div className="ion-hide-xl-up"><b><ion-text color="light">Take Photo</ion-text></b></div>
                        <div className="ion-hide-xl-down"><b><ion-text color="light">Upload Photo</ion-text></b></div>
                        </a>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        {uploadedImage && <IonImg src={uploadedImage}>

                        </IonImg>}
                    </IonCol>
                </IonRow>
            </IonGrid>
    )
}

export default UploadEntryImage
