import React, {useEffect, useState} from "react";
import {IonIcon, IonCol, IonLabel} from "@ionic/react";
import {checkmarkOutline} from "ionicons/icons";

const SubmitStatus = (props) => {
    const {
        status,
        processedText,
        processingText,
        errorText,
    } = props

    const [displayText, setDisplayText] = useState('')
    useEffect(() => {

        switch (status) {
            case "pending":
                setDisplayText(processingText)
                break;
            case "success":
                setDisplayText(processedText)
                break
            case "error":
                setDisplayText(errorText)
        }
    }, [status])
    return (
    <IonCol>
      <div className={`text-${status}`}>
          <IonLabel>


            <span>
            <ion-spinner name="crescent" class="status-spinner"></ion-spinner>
            <IonIcon
                class="ion-float-left status-tick"
                style={{ fontSize: "10px", color: "#28cc00", width:"20px", height:"20px", padding:"0" }}
                icon={checkmarkOutline}
            />
            </span>
            <span>
            {displayText}
            </span>
          </IonLabel>
      </div>
    </IonCol>
    )
}

export default SubmitStatus
