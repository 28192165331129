import {useDispatch} from "react-redux";
import {IonCard, IonCardContent, IonCardHeader} from "@ionic/react";
import React, {useEffect, useState} from "react";
import { getLeaderboard} from "../../store/features/dashboard/thunks";
import Spinner from '../Spinner.js'
import './leaderboard.css';

const Leaderboard = ({options}) => {
    const {companyId} = options
    const dispatch = useDispatch()
    const [leaderboard, setLeaderboard] = useState(null)

    useEffect(() => {

        if(companyId)
        dispatch(getLeaderboard({companyId})).then(res => {
            console.log(res, 'res')
            setLeaderboard(res.payload.data)

        })
    }, [dispatch,companyId])



    return (

        <IonCard className="leaderboard-card">
            <IonCardHeader className="leaderboard-card-header">
                <h2 className="leaderboard-card-title">Leader board</h2>
                <p className="leaderboard-card-subtitle">Top 10 team members</p>
            </IonCardHeader>

            <IonCardContent>
                <ion-list>

                    {leaderboard ? leaderboard.map(user => {
                            return (
                                        <div className="leaderboard-user-card" key={user.id}>
                                            <div className="d-flex items-center">
                                                <div className="initials-badge">
                                                   {user.name.split(" ")[0][0].toUpperCase() + user.name.split(" ")[1][0].toUpperCase()}
                                                </div>
                                                <div>
                                                    <p>{user.name}</p>
                                                    <p>{user.email}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex items-center justify-center count-badge">
                                                   {user.cnt}
                                            </div>
                                        </div>
                            )
                        }
                    ) : 
                    <Spinner />}
                </ion-list>
            </IonCardContent>
        </IonCard>

    )
}

export default Leaderboard