import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getCurrentMonthEntriesByUser} from "../../store/features/entry/thunks";
import {
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import "../../Utility.css";

const CurrentMonthEntriesByUser = ({options}) => {
  const dispatch = useDispatch();
  const {userId} = options;
  const userEntries = useSelector((state) => state.entry?.list);

  const date = new Date();
  const dateOptions = {year: "numeric", month: "long"};
  const formattedDate = date.toLocaleString("en-GB", dateOptions);

  const formatDate = (date) => {
    const dateToFormat = new Date(date);

    const fDate = dateToFormat.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const fTime = dateToFormat.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${fDate} ${fTime}`;
  };


  return (
    <IonCard className="ion-card-gray" style={{padding: '15px'}}>
        <div>
            <div class="flex justify-between align-center">
                <IonCardTitle class="mb-sm mt-md">Entries - {formattedDate}</IonCardTitle>
            </div>
            <IonCardSubtitle>
              A list of all the customers in your account for {formattedDate}
            </IonCardSubtitle>
        </div>
      
        <h3 class="mb-sm mt-md">{formattedDate}</h3>

        {!userEntries && <p>No entries found</p>}
        {userEntries &&
          userEntries.map((entry) => {
            return (
              <div class="leaderboard-user-card">
                    <div class="flex align-center">
                        <ion-avatar>
                          <img alt="Silhouette of mountains" src={entry.photo} />
                        </ion-avatar>
                        <div className="pl-md">
                          <p class="bold">
                            {entry.firstname} {entry.lastname}
                          </p>
                          <p>{entry.email}</p>
                        </div>
                    </div>
                  <div className="flex justify-between align-center" style={{width: '50%'}}>
                    <p className="bold">{entry.product}</p>
                    <p>{formatDate(entry.createdon)}</p>
                  </div>
     
              </div>
            );
          })}
      
    </IonCard>
  );
};

export default CurrentMonthEntriesByUser;
